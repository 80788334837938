import { Text } from '@backyard-ui/core';

function SEODescription() {
  return (
    <div
      className="px-9 py-2 [&_a]:text-green-700"
      data-testid="footer-copyright"
    >
      <Text size="sm" align="justify">
        Considerada multiespecialista do lar, especializada em Construção,
        Acabamento,{' '}
        <a
          href="https://www.leroymerlin.com.br/decoracao"
          title="Decoração para casa"
        >
          Decoração
        </a>
        , Jardinagem e{' '}
        <a
          href="https://www.leroymerlin.com.br/blog/bricolagem"
          title="Faça você mesmo"
        >
          Bricolagem{' '}
        </a>
        , a Leroy Merlin apresenta aos seus Clientes a maior variedade de
        produtos, são mais de 100 mil itens divididos em diversos departamentos.
        Aqui, você encontra{' '}
        <a
          href="https://www.leroymerlin.com.br/materiais-de-construcao"
          title="Materiais de Construção"
        >
          Materiais de Construção
        </a>
        ,{' '}
        <a
          href="https://www.leroymerlin.com.br/cozinhas-planejadas"
          title="Cozinhas Planejadas"
        >
          Cozinhas Planejadas
        </a>
        ,{' '}
        <a
          href="https://www.leroymerlin.com.br/ferramentas"
          title="Ferramentas gerais"
        >
          Ferramentas
        </a>
        ,{' '}
        <a href="https://www.leroymerlin.com.br/furadeiras" title="Furadeiras">
          Furadeiras
        </a>
        ,{' '}
        <a
          href="https://www.leroymerlin.com.br/pisos-e-revestimentos"
          title="Pisos e Revestimentos"
        >
          Pisos e Revestimentos
        </a>
        , Sanitários, Encanamentos,{' '}
        <a
          href="https://www.leroymerlin.com.br/tapetes-tapetes"
          title="Tapetes"
        >
          Tapetes
        </a>
        , Almofadas, Iluminação,{' '}
        <a href="https://www.leroymerlin.com.br/moveis" title="Móveis">
          Móveis
        </a>
        ,{' '}
        <a
          href="https://www.leroymerlin.com.br/eletrodomesticos"
          title="Eletrodomésticos"
        >
          Eletrodomésticos
        </a>
        ,{' '}
        <a href="https://www.leroymerlin.com.br/tintas" title="Tintas">
          Tintas
        </a>
        , Ferragens,{' '}
        <a
          href="https://www.leroymerlin.com.br/ar-condicionado"
          title="Ar condicionado"
        >
          Ar Condicionado
        </a>
        , produtos de{' '}
        <a
          href="https://www.leroymerlin.com.br/cama-mesa-e-banho"
          title="Cama, Mesa e Banho"
        >
          cama, mesa e banho
        </a>{' '}
        e muito mais. As Lojas oferecem serviços diferenciados, como Cursos de
        Bricolagem, Corte de Madeira, Fábrica de Cores, Molduraria,
        Estacionamento, Café, Entrega em Domicílio, Troca de Mercadoria, Coleta
        Seletiva, Fraldário, Soluções Financeiras, entre outros. Hoje, a Leroy
        Merlin Brasil possui 47{' '}
        <a
          href="https://www.leroymerlin.com.br/lojas"
          title="Lojas Leroy Merlin"
        >
          Lojas
        </a>{' '}
        distribuídas em 11 estados brasileiros e o Distrito Federal. Não tenha
        dúvidas: a Leroy Merlin pode ajudar você em todas as etapas de sua obra.
        Navegue pelo site da empresa e conheça um pouco mais a companhia, seus
        departamentos e produtos, além das diversas Ofertas disponíveis. Seja
        bem vindo à casa da sua casa!
      </Text>

      <Text size="sm">
        A Leroy Merlin Cia. Brasileira de Bricolagem, inscrita no CNPJ nº
        01.438.784/0001-05, atua exclusivamente como correspondente bancário nos
        termos da Resolução nº 4.935 do Banco Central do Brasil. Essa atuação
        limita-se à intermediação de serviços financeiros oferecidos pela PEFISA
        S/A - CFI, inscrita no CNPJ sob o nº 43.180.355/0001-1, sem qualquer
        exercício de atividades privativas de instituições financeiras ou de
        seus empregados.
      </Text>

      <Text color="gray-600" size="sm" align="center">
        Copyright © 2021 Leroy Merlin, todos os direitos reservados. Leroy
        Merlin Cia Brasileira de Bricolagem. Inscrição estadual nº
        298.176.665.115 CNPJ/MF sob o nº 01.438.784/0048-60. Rua Pascoal Pais,
        nº 525, 5º andar - Vila Cordeiro, CEP 04581-060, São Paulo/SP.
      </Text>
    </div>
  );
}

export default SEODescription;
