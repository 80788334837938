'use client';

import { useMemo } from 'react';

import {
  Root,
  TopBar,
  Container,
  Burger,
  Logo,
  Searchbar,
  Actions,
  Navigation,
} from '@leroy-merlin-br/backpack-header';

import { type HeaderMenu } from '@/infra/services/categories/get-header-menu';
import { useAnalyticsTags } from '@/presenters/hooks/algolia/useAnalyticsTags';
import { useGlobalStore } from '@/presenters/store/global';
import {
  indexName,
  insightsConfig,
  searchClient,
  retailMediaIndexName,
} from '@/utils/algolia';

import {
  type HeaderBackpackMenus,
  locationContextAdapter,
  menuAdapter,
  userAdapter,
} from './adapters';
import { onUserRecontext, redirect } from './Header.utils';
import { locationRepository, navigationRepository } from './repositories';

export interface HeaderProps {
  menus: HeaderMenu[];
}

export default function Header(props: HeaderProps) {
  const { menus: menusFromProps } = props;

  const analyticsTags = useAnalyticsTags();
  const userState = useGlobalStore((state) => state.user);
  const theme =
    process.env.NEXT_PUBLIC_FEATURE_FLIP_BLACK_FRIDAY_ENABLED === 'true'
      ? 'black-weekend'
      : 'default';

  const menus: HeaderBackpackMenus = useMemo(() => {
    return menuAdapter(menusFromProps);
  }, [menusFromProps]);

  const user = useMemo(() => {
    return userAdapter(userState);
  }, [userState]);

  const locationContext = useMemo(() => {
    return locationContextAdapter(userState.session);
  }, [userState]);

  return (
    <Root
      data-testid="header"
      user={user}
      locationContext={locationContext}
      cartItems={Number(userState.resources?.cartItems || '0')}
      routing
      theme={theme}
    >
      <TopBar
        handleLocationRecontext={onUserRecontext}
        locationRepository={locationRepository}
      />

      <Container>
        <Burger />
        <Logo />
        <Searchbar
          algolia={{
            indexName: indexName,
            insightsClient: insightsConfig.insightsClient,
            searchClient,
            retailMediaIndexName,
            queries: [{ params: { analyticsTags }, indexName }],
          }}
        />
        <Actions
          providers={{
            disable: ['facebook'],
          }}
          onClickCart={() => redirect('/carrinho')}
        />
      </Container>

      <Navigation data={menus} navigationRepository={navigationRepository} />
    </Root>
  );
}
