import { type ComponentProps } from 'react';

import { type Navigation } from '@leroy-merlin-br/backpack-header';

import { type HeaderMenu } from '@/infra/services/categories/get-header-menu';
import { type GlobalState } from '@/presenters/store/global';

type HeaderBackpackNavigation = ComponentProps<typeof Navigation>;
export type HeaderBackpackMenus = HeaderBackpackNavigation['data'];

export function menuAdapter(menus: HeaderMenu[]): HeaderBackpackMenus {
  return menus.map((menu) => {
    const remapedItems = menu.items?.map((item) => ({
      id: item.id,
      name: item.name,
      url: item.url,
      hasChildren: item.hasChildren,
    }));

    return {
      id: menu.id,
      name: menu.name,
      url: menu.url,
      items: remapedItems,
      hasChildren: Boolean(remapedItems),
    };
  });
}

export function userAdapter(user: GlobalState['user']) {
  return {
    email: user.resources?.email ?? '',
    name: user.resources?.name ?? '',
    picture: user.resources?.picture ?? '',

    loyaltyPoints: user.loyaltyProfile?.points.actual ?? 0,

    isLegalUser: Boolean(user.resources?.isLegalUser),
    isAuthenticated: Boolean(user.controls?.isLoggedIn),
    isAssistedSale: Boolean(user.controls?.isAssistedSale),
    isLoyaltyMember: Boolean(user.controls?.isLoyaltyMember),
    canJoinLoyalty: Boolean(user.controls?.isLoyaltyEligible),
  };
}

export function locationContextAdapter(
  session: GlobalState['user']['session']
) {
  return {
    region: session.region,
    regionName: session.regionName,
    locationSource: session.locationSource || 'user',
  };
}
