import { type Hit } from 'algoliasearch';

import { type ProductThumbProps } from '@/presenters/components/common/ProductThumb';

export type RegionalAttributesType = {
  promotionalPrice: number;
  originalPrice: number;
  suggestedPrice: number;
  available: boolean;
  availableForEcommerce: boolean;
  offer: {
    enabled: boolean;
    type: string;
    label: string;
    value: number;
  };
  installments?: {
    amount: number;
    value: string;
    totalPrice: string;
  };
  discount: {
    discountType: string;
    paymentMethodDiscount: string;
    featureInstallmentDiscount: boolean;
  };
  facets: {
    blackFriday?: boolean;
    freeShipping?: boolean;
    expressShipping?: boolean;
  };
};

export interface ProductProps {
  objectID: string;
  name: string;
  url: string;
  relevanceFields: {
    purchasedQuantity?: number;
    exclusiveBrand?: boolean;
    exclusiveMarkeplace?: boolean;
    isOurLowestPrice?: boolean;
    novelty?: boolean;
  };
  attributes: {
    [key: string]: string;
  };
  categoryPageId: string[];
  regionalAttributes: {
    [key: string]: RegionalAttributesType;
  };
  pictures: {
    micro: string;
    normal: string;
  };
  unit: string;
  __position: number;
  __queryID?: string;
}

const getTag = (
  facets: RegionalAttributesType['facets'],
  relevanceFields: ProductProps['relevanceFields']
): ProductThumbProps['tag'] => {
  const tags: Array<{ condition: boolean; tag: ProductThumbProps['tag'] }> = [
    {
      condition: Boolean(facets?.blackFriday),
      tag: { type: 'outlet', label: 'Black Friday' },
    },
    {
      condition: Boolean(facets?.freeShipping),
      tag: { type: 'freeShipping', label: 'Frete Grátis' },
    },
    {
      condition: Boolean(relevanceFields?.isOurLowestPrice),
      tag: { type: 'lowestprice', label: 'Nosso preço + baixo' },
    },
    {
      condition: Boolean(relevanceFields?.exclusiveMarkeplace),
      tag: { type: 'exclusive', label: 'Exclusivo Site' },
    },
    {
      condition: Boolean(relevanceFields?.novelty),
      tag: { type: 'news', label: 'NOVIDADE' },
    },
  ];

  return tags.find((tag) => tag.condition)?.tag;
};

export function normalizeProduct(product: Hit<ProductProps>, region: string) {
  const regionalData = product.regionalAttributes[region];

  return {
    id: Number(product.objectID),
    shortName: product.name,
    categoryTree: product.categoryPageId[0],
    tag: getTag(regionalData.facets, product.relevanceFields),
    price: {
      suggested: regionalData.suggestedPrice,
      from: regionalData.originalPrice,
      to: regionalData.promotionalPrice,
      discount: regionalData.offer.enabled
        ? {
            percentage: regionalData.offer.value,
            type: regionalData.offer.type,
            labels: {
              paymentMethod: regionalData.discount.paymentMethodDiscount,
              offer: regionalData.discount.discountType,
            },
            featureInstallmentDiscount:
              regionalData.discount.featureInstallmentDiscount,
          }
        : undefined,
      installments: regionalData.installments && {
        amount: regionalData.installments.amount,
        value: parseFloat(regionalData.installments.value.replace(',', '.')),
      },
    },
    unit: product.unit,
    url: product.url,
    brand: product.attributes?.Marca ?? '',
    picture: product.pictures.normal,
    meta: {
      hasService: false,
      inWishlist: false,
      isOutlet: Boolean(regionalData.facets?.blackFriday),
      isSoldOut: false,
      expressDeliveryDay: undefined,
    },
  };
}
